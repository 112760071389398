import { render, staticRenderFns } from "./Tournament2022.vue?vue&type=template&id=b3287a1a&scoped=true&"
import script from "./Tournament2022.vue?vue&type=script&lang=js&"
export * from "./Tournament2022.vue?vue&type=script&lang=js&"
import style0 from "./Tournament2022.vue?vue&type=style&index=0&id=b3287a1a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3287a1a",
  null
  
)

export default component.exports