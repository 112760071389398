<template>
	<div class="tournament">
		<SubHeader
			title="JDO Tournament Prize Winner"
			subtitle="JDOトーナメント入賞者"
		/>

		<div class="container">
			<ul class="nav nav-pills mb-3" id="myTab" role="tablist">
				<li class="nav-item">
					<a class="nav-link" href="/jdo/tournament2024"> 2024年度 </a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/jdo/tournament2023"> 2023年度 </a>
				</li>
				<li class="nav-item">
					<a class="nav-link active" href="javascript:void(0);"> 2022年度 </a>
				</li>
			</ul>

			<div id="20230326_kyoto" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2023.3.26 Kyoto Open Darts Tournament 17th</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">オープンダブルス 優勝</span>
							<img src="/images/jdo/2023kyoto/kyoto_01.jpg" class="img-fluid" />
							<span class="name">高山 得溶・谷田 孝夫</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">オープンダブルス 3位タイ</span>
							<img src="/images/jdo/2023kyoto/kyoto_02.jpg" class="img-fluid" />
							<span class="name">小宮山 亜美・石井 庸介</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">レディースシングルス 3位タイ</span>
							<img src="/images/jdo/2023kyoto/kyoto_03.jpg" class="img-fluid" />
							<span class="name">嵩 佳央梨</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 優勝</span>
							<img src="/images/jdo/2023kyoto/kyoto_04.jpg" class="img-fluid" />
							<span class="name">由肥 元希</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 準優勝</span>
							<img src="/images/jdo/2023kyoto/kyoto_05.jpg" class="img-fluid" />
							<span class="name">中井 涼</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 3位タイ</span>
							<img src="/images/jdo/2023kyoto/kyoto_06.jpg" class="img-fluid" />
							<span class="name">芳村 幹陽</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20230312_aichi" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2023.3.12 第18回 ADO Darts Open Tournament</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス 優勝</span>
							<img src="/images/jdo/2023aichi/aichi_01.jpg" class="img-fluid" />
							<span class="name">由肥 元希</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">オープンダブルス 3位タイ</span>
							<img src="/images/jdo/2023aichi/aichi_02.jpg" class="img-fluid" />
							<span class="name">田中 にこる・工藤 光生</span>
						</p>
					</div>
					<div class="col-lg-4 text-center">
						<p>
							<span class="rank">オープンダブルス 3位タイ</span>
							<img src="/images/jdo/2023aichi/aichi_03.jpg" class="img-fluid" />
							<span class="name">高山 得溶・荒井 大輔</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20230226_ehime" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2023.2.26 OPEN DARTS TOUNAMENT IN EHIME 2023</h4>
				</div>
				<div class="row mb-4">
					<div class="col-6 col-lg-4 text-center offset-lg-2">
						<p>
							<span class="rank">オープンダブルス 3位タイ</span>
							<img src="/images/jdo/2023ehime/ehime_02.jpg" class="img-fluid" />
							<span class="name">大本 直樹・高山 得溶</span>
						</p>
					</div>
					<div class="col-6 col-lg-4 text-center">
						<p>
							<span class="rank">メンズシングルス ３位タイ</span>
							<img src="/images/jdo/2023ehime/ehime_01.jpg" class="img-fluid" />
							<span class="name">高山 得溶</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20221009_interleague" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2022.10.9 JDO INTERLEAGUE CHAMPIONSHIP 2022</h4>
				</div>
				<div class="row mb-4">
					<div class="col-lg-6 text-center offset-lg-5">
						<p>
							<span class="rank">優勝</span>
							<img
								src="/images/jdo/2022interleague/just01.jpg"
								class="img-fluid"
							/>
							<span class="name"
								>JUST<br />（角家 元二、松本 康寿、倉戸 亮、田原 駿也、西本
								陽一、石井 庸介、Paul Winterburn、浅田 斉吾）</span
							>
						</p>
					</div>
				</div>
			</div>

			<div id="20220925_niigata" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2022.9.25 新潟 Darts Open Tournament 2022</h4>
				</div>
				<div class="row mb-4">
					<div class="col-6 col-lg-4 text-center offset-lg-2">
						<p>
							<span class="rank">オープンダブルス 準優勝</span>
							<img
								src="/images/jdo/2022niigata/hayashi_kogama.jpg"
								class="img-fluid"
							/>
							<span class="name">鈳 孝子・林 裕之</span>
						</p>
					</div>
					<div class="col-6 col-lg-4 text-center">
						<p>
							<span class="rank">レディースシングルス ３位タイ</span>
							<img src="/images/jdo/2022niigata/kogama.jpg" class="img-fluid" />
							<span class="name">鈳 孝子</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20220703_hdo" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2022.7.3 第31回 H.D.O.オープンダーツトーナメント大会</h4>
				</div>
				<div class="row mb-4">
					<div class="col-6 col-lg-3 text-center">
						<p>
							<span class="rank">オープンダブルス 優勝</span>
							<img src="/images/jdo/2022hdo/ishii.jpg" class="img-fluid" />
							<span class="name">（愛知）中西 永吉・石井 庸介</span>
						</p>
					</div>
					<div class="col-6 col-lg-3 text-center">
						<p>
							<span class="rank">オープンダブルス 準優勝</span>
							<img
								src="/images/jdo/2022hdo/sotoosa_nakanishi.jpg"
								class="img-fluid"
							/>
							<span class="name">外筬 久人・中西 信</span>
						</p>
					</div>
					<div class="col-6 col-lg-3 text-center">
						<p>
							<span class="rank">メンズシングルス 準優勝</span>
							<img src="/images/jdo/2022hdo/yuhi.jpg" class="img-fluid" />
							<span class="name">由肥 元希</span>
						</p>
					</div>
					<div class="col-6 col-lg-3 text-center">
						<p>
							<span class="rank">レディースシングルス 優勝</span>
							<img src="/images/jdo/2022hdo/okada.jpg" class="img-fluid" />
							<span class="name">（大阪一般）岡田 亜希子</span>
						</p>
					</div>
				</div>
			</div>

			<div id="20220702_masters" class="row d-flex align-items-end mb-4">
				<div class="col-12">
					<h4>2022.7.2 JDOマスターズ</h4>
				</div>
				<div class="row mb-4">
					<div class="col-6 col-lg-3 text-center offset-lg-3">
						<p>
							<span class="rank">メンズマスターズ G1 3位タイ</span>
							<img
								src="/images/jdo/2022masters/takayama.jpg"
								class="img-fluid"
							/>
							<span class="name">高山 得溶</span>
						</p>
					</div>
					<div class="col-6 col-lg-3 text-center">
						<p>
							<span class="rank">レディースマスターズ G1 3位タイ</span>
							<img src="/images/jdo/2022masters/kogama.jpg" class="img-fluid" />
							<span class="name">鈳 孝子</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import SubHeader from "@/components/common/SubHeader.vue";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
	name: "tournament2020",
	components: {
		SubHeader,
	},
	data() {
		return {};
	},
	mounted: function () {},
	methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.6rem;
	margin-bottom: 1rem;
}
h4 {
	border-bottom: solid 3px #333;
	padding-bottom: 5px;
	margin-bottom: 2rem;
	position: relative;
	&:before {
		content: "";
		width: 3rem;
		height: 3px;
		background-color: $primary-color;
		display: block;
		position: absolute;
		left: 0;
		bottom: -3px;
	}
}
a {
	text-decoration: underline;
}
.container {
	padding: 4rem 1rem;
	text-align: left;
}
.btn-download {
	font-size: 1rem;
	color: #333;
	border: none;
	margin-top: 1rem;
	background: $primary-color;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		color: #333;
		background-color: $secondary-color;
	}
	&:active {
		color: #333;
		background-color: $secondary-color;
	}
}
.rank,
.rank_winner,
.rank_2nd,
.rank_3rd,
.name {
	color: #333;
}
.rank_winner {
	font-size: 2rem;
	i {
		color: #dbb400;
	}
}
.rank_2nd {
	font-size: 1.4rem;
	i {
		color: #9fa0a0;
	}
}
.rank_3rd {
	font-size: 1.4rem;
	i {
		color: #c47022;
	}
}
i {
	margin-right: 0.6rem;
}
</style>
